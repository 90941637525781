<template>
  <div class="btn-scroll-to-top show">
    <ul id="list" v-if="show">
      <HorizontalCollapse/>
    </ul>
  </div>
</template>

<script>
import { useWindowScroll } from "@vueuse/core";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import RemindersItem from "./RemindersItem.vue";
import HorizontalCollapse from "./components/HorizontalCollapse";
import store from "@/store";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    RemindersItem,
    HorizontalCollapse,
  },

  methods: {
  },

  data() {
    return {
      show: false,
      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,
    };
  },

  watch: {
    $route: function (newVal, oldVal) {
      if (
        newVal.name === "auth-login" ||
        newVal.name === null ||
        newVal.name === undefined ||
        newVal.name === "welcome-page"
      ) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },

  setup() {
    const { y } = useWindowScroll();

    const scrollToTop = () => {
      const rootEle = document.documentElement;
      rootEle.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return { y, scrollToTop };
  },
};
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: -6%;
  left: 208px;
  z-index: 99;

  opacity: 0;
  // transform: translateY(100px);
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
    // transform: translateY(0)
  }
}
</style>
