import mock from "@/@fake-db/mock";
/* eslint-disable global-require */
const data = {
  faqData: {
    payment: {
      title: "Payment",
      subtitle: "Which license do I need?",
      qandA: [
        {
          question: "Does my subscription automatically renew?",
          ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
        },
      ],
    },
    delivery: {
      icon: "ShoppingBagIcon",
      title: "Delivery",
      subtitle: "Which license do I need?",
      qandA: [
        {
          question: "Where has my order reached?",
          ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
        },
      ],
    },
    cancellationReturn: {
      icon: "RefreshCwIcon",
      title: "Cancellation & Return",
      subtitle: "Which license do I need?",
      qandA: [
        {
          question:
            "Can my security guard or neighbour receive my shipment if I am not available?",
          ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
        },
      ],
    },
    myOrders: {
      icon: "PackageIcon",
      title: "My Orders",
      subtitle: "Which license do I need?",
      qandA: [
        {
          question: "Can I avail of an open delivery?",
          ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
        },
      ],
    },
    // product and services
    productServices: {
      icon: "SettingsIcon",
      title: "Product & Services",
      subtitle: "Which license do I need?",
      qandA: [
        {
          question:
            "How can I register a complaint against the courier executive who came to deliver my order?",
          ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
        },
      ],
    },
  },
};

mock.onGet("/faq/data").reply((config) => {
  const { q = "" } = config.params;
  const queryLowered = q.toLowerCase();

  const filteredData = {};

  Object.entries(data.faqData).forEach((entry) => {
    const [categoryName, categoryObj] = entry;
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter((qAndAObj) => {
      return qAndAObj.question.toLowerCase().includes(queryLowered);
    });
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = {
        ...categoryObj,
        qandA: filteredQAndAOfCategory,
      };
    }
  });

  return [200, filteredData];
});
