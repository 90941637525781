export default [
  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*

  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  // *=================================================*
  // *------------ META VUE --------------------------*
  //*==================================================*

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
    meta: {
      pageTitle: "user",
      breadcrumb: [
        {
          text: "userList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
    meta: {
      pageTitle: "user",
      breadcrumb: [
        {
          text: "userList",
          to: "/apps/users/list",
        },
        {
          text: "userDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
    meta: {
      pageTitle: "user",
      breadcrumb: [
        {
          text: "userList",
          to: "/apps/users/list",
        },
        {
          text: "userDetails",
          to: {
            name: "apps-users-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "userEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/user/profile/:id",
    name: "apps-user-profile",
    component: () => import("@/views/apps/user/profile/ProfileView.vue"),
    meta: {
      pageTitle: "profile",
      breadcrumb: [
        {
          text: "profile",
          active: true,
        },
      ],
    },
  },

  // Employee

  {
    path: "/apps/employees/list",
    name: "apps-employees-list",
    component: () =>
      import("@/views/apps/employee/employees-list/EmployeesList.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "employee",
      breadcrumb: [
        {
          text: "employeeList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/employees/view/:id",
    name: "apps-employees-view",
    component: () =>
      import("@/views/apps/employee/employees-view/EmployeesView.vue"),
    meta: {
      pageTitle: "employee",
      breadcrumb: [
        {
          text: "employeeList",
          to: "/apps/employees/list",
        },
        {
          text: "employeeDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/employees/edit/:id",
    name: "apps-employees-edit",
    component: () =>
      import("@/views/apps/employee/employees-edit/EmployeesEdit.vue"),
    meta: {
      pageTitle: "employee",
      breadcrumb: [
        {
          text: "employeeList",
          to: "/apps/employees/list",
        },
        {
          text: "employeeDetails",
          to: {
            name: "apps-employees-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "employeeEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/employees/cards",
    name: "apps-employees-cards",
    component: () =>
      import("@/views/apps/employee/employees-cards/EmployeeCards.vue"),
    meta: {
      pageTitle: "employee",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "employeeCards",
          active: true,
        },
      ],
    },
  },

  // Project

  {
    path: "/apps/project/list",
    name: "apps-project-list",
    component: () =>
      import("@/views/apps/project/project-list/ProjectsList.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "project",
      breadcrumb: [
        {
          text: "projectList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/project/view/:id",
    name: "apps-project-view",
    component: () =>
      import("@/views/apps/project/project-view/ProjectsView.vue"),
    meta: {
      pageTitle: "project",
      breadcrumb: [
        {
          text: "projectList",
          to: "/apps/project/list",
        },
        {
          text: "projectDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/project/edit/:id",
    name: "apps-project-edit",
    component: () =>
      import("@/views/apps/project/project-edit/ProjectsEdit.vue"),
    meta: {
      pageTitle: "project",
      breadcrumb: [
        {
          text: "projectList",
          to: "/apps/project/list",
        },
        {
          text: "projectDetails",
          to: {
            name: "apps-projects-details",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "projectEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/projects/cards",
    name: "apps-projects-cards",
    component: () =>
      import("@/views/apps/project/projects-cards/ProjectCards.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "project",
      breadcrumb: [
        {
          text: "projectCards",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/projects/details/:id",
    name: "apps-projects-details",
    component: () =>
      import("@/views/apps/project/projects-details/ProjectDetails.vue"),
    meta: {
      pageTitle: "project",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "projectCards",
          to: "/apps/projects/cards",
        },
        {
          text: "projectDetails",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/projects/kanban/:id",
    name: "apps-projects-kanban",
    component: () =>
      import("@/views/apps/project/projects-kanban/ProjectKanban.vue"),
    meta: {
      pageTitle: "project",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "projectCards",
          to: "/apps/projects/cards",
        },
        {
          text: "projectDetails",
          to: {
            name: "apps-projects-details",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "projectKanban",
          active: true,
        },
      ],
    },
  },

  // Commands

  {
    path: "/apps/command",
    name: "apps-command",
    component: () => import("@/views/apps/command/Command.vue"),
    meta: {
      pageTitle: "commands",
      breadcrumb: [
        {
          text: "commands",
          active: true,
        },
      ],
    },
  },

  // Purge

  {
    path: "/apps/purge",
    name: "apps-database-purge",
    component: () => import("@/views/apps/purge/Purge.vue"),
    meta: {
      pageTitle: "purge",
      breadcrumb: [
        {
          text: "purge",
          active: true,
        },
      ],
    },
  },  

  // Param

  {
    path: "/apps/param",
    name: "apps-param",
    component: () => import("@/views/apps/param/Param.vue"),
    meta: {
      pageTitle: "param",
      breadcrumb: [
        {
          text: "param",
          active: true,
        },
      ],
    },
  },

  // Access Control

  {
    path: "/apps/accessControl",
    name: "apps-access-control",
    component: () => import("@/views/apps/access-control/AccessControl.vue"),
    meta: {
      pageTitle: "accessControl",
      breadcrumb: [
        {
          text: "accessControl",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- WIKI ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/apps/wiki/wiki",
    name: "apps-wiki",
    component: () => import("@/views/apps/wiki/Wiki.vue"),
    meta: {
      pageTitle: "wiki",
      breadcrumb: [
        {
          text: "wiki",
          active: true,
        },
      ],
    },
  },

  // TimeSheet

  {
    path: "/apps/time-sheet/list",
    name: "apps-time-sheet-list",
    component: () =>
      import("@/views/apps/time-sheet/time-sheet-list/TimeSheetsList.vue"),
    meta: {
      pageTitle: "timeSheet",
      breadcrumb: [
        {
          text: "timeSheetList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/time-sheet/view/:id",
    name: "apps-time-sheet-view",
    component: () =>
      import("@/views/apps/time-sheet/time-sheet-view/TimeSheetsView.vue"),
    meta: {
      pageTitle: "timeSheet",
      breadcrumb: [
        {
          text: "timeSheetList",
          to: "/apps/time-sheet/list",
        },
        {
          text: "timeSheetDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/time-sheet/edit/:id",
    name: "apps-time-sheet-edit",
    component: () =>
      import("@/views/apps/time-sheet/time-sheet-edit/TimeSheetsEdit.vue"),
    meta: {
      pageTitle: "timeSheet",
      breadcrumb: [
        {
          text: "timeSheetList",
          to: "/apps/time-sheet/list",
        },
        {
          text: "timeSheetDetails",
          to: {
            name: "apps-time-sheet-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "timeSheetEdit",
          active: true,
        },
      ],
    },
  },

  // Bill

  {
    path: "/apps/bill/list",
    name: "apps-bill-list",
    component: () => import("@/views/apps/bill/bill-list/BillsList.vue"),
    meta: {
      pageTitle: "bill",
      breadcrumb: [
        {
          text: "billList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/bill/view/:id",
    name: "apps-bill-view",
    component: () => import("@/views/apps/bill/bill-view/BillsView.vue"),
    meta: {
      pageTitle: "bill",
      breadcrumb: [
        {
          text: "billList",
          to: "/apps/bill/list",
        },
        {
          text: "billDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/bill/edit/:id",
    name: "apps-bills-edit",
    component: () => import("@/views/apps/bill/bill-edit/BillsEdit.vue"),
    meta: {
      pageTitle: "bill",
      breadcrumb: [
        {
          text: "billList",
          to: "/apps/bill/list",
        },
        {
          text: "billDetails",
          to: {
            name: "apps-bill-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "billEdit",
          active: true,
        },
      ],
    },
  },

  // Team

  {
    path: "/apps/team/list",
    name: "apps-team-list",
    component: () => import("@/views/apps/team/team-list/TeamsList.vue"),
    meta: {
      pageTitle: "team",
      breadcrumb: [
        {
          text: "teamList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/team/view/:id",
    name: "apps-team-view",
    component: () => import("@/views/apps/team/team-view/TeamsView.vue"),
    meta: {
      pageTitle: "team",
      breadcrumb: [
        {
          text: "teamList",
          to: "/apps/team/list",
        },
        {
          text: "teamDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/team/edit/:id",
    name: "apps-team-edit",
    component: () => import("@/views/apps/team/team-edit/TeamsEdit.vue"),
    meta: {
      pageTitle: "team",
      breadcrumb: [
        {
          text: "teamList",
          to: "/apps/team/list",
        },
        {
          text: "teamDetails",
          to: {
            name: "apps-team-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "teamEdit",
          active: true,
        },
      ],
    },
  },

  // Contract

  {
    path: "/apps/contract/list",
    name: "apps-contract-list",
    component: () =>
      import("@/views/apps/contract/contract-list/ContractsList.vue"),
    meta: {
      pageTitle: "contract",
      breadcrumb: [
        {
          text: "contractList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/contract/view/:id",
    name: "apps-contract-view",
    component: () =>
      import("@/views/apps/contract/contract-view/ContractsView.vue"),
    meta: {
      pageTitle: "contract",
      breadcrumb: [
        {
          text: "contractList",
          to: "/apps/contract/list",
        },
        {
          text: "contractDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/contract/edit/:id",
    name: "apps-contract-edit",
    component: () =>
      import("@/views/apps/contract/contract-edit/ContractsEdit.vue"),
    meta: {
      pageTitle: "contract",
      breadcrumb: [
        {
          text: "contractList",
          to: "/apps/contract/list",
        },
        {
          text: "contractDetails",
          to: {
            name: "apps-contract-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "contractEdit",
          active: true,
        },
      ],
    },
  },

  //Opportunities
  {
    path: "/apps/opportunity/list",
    name: "apps-opportunity-list",
    component: () =>
      import("@/views/apps/opportunity/opportunity-list/OpportunitiesList.vue"),
    meta: {
      pageTitle: "opportunity",
      breadcrumb: [
        {
          text: "opportunityList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/opportunity/edit/:id",
    name: "apps-opportunities-edit",
    component: () =>
      import("@/views/apps/opportunity/opportunity-edit/OpportunitiesEdit.vue"),
    meta: {
      pageTitle: "opportunity",
      breadcrumb: [
        {
          text: "opportunityList",
          to: "/apps/opportunity/list",
        },
        {
          text: "opportunityDetails",
          to: {
            name: "apps-opportunity-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "opportunityEdit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/opportunity/view/:id",
    name: "apps-opportunity-view",
    component: () =>
      import("@/views/apps/opportunity/opportunity-view/OpportunitiesView.vue"),
    meta: {
      pageTitle: "opportunity",
      breadcrumb: [
        {
          text: "opportunityList",
          to: "/apps/opportunity/list",
        },
        {
          text: "opportunityDetails",
          active: true,
        },
      ],
    },
  },

  // Customer

  {
    path: "/apps/customer/list",
    name: "apps-customer-list",
    component: () =>
      import("@/views/apps/customer/customer-list/CustomersList.vue"),
    meta: {
      pageTitle: "customer",
      breadcrumb: [
        {
          text: "customerList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/customer/view/:id",
    name: "apps-customer-view",
    component: () =>
      import("@/views/apps/customer/customer-view/CustomersView.vue"),
    meta: {
      pageTitle: "customer",
      breadcrumb: [
        {
          text: "customerList",
          to: "/apps/customer/list",
        },
        {
          text: "customerDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/customer/edit/:id",
    name: "apps-customer-edit",
    component: () =>
      import("@/views/apps/customer/customer-edit/CustomersEdit.vue"),
    meta: {
      pageTitle: "customer",
      breadcrumb: [
        {
          text: "customerList",
          to: "/apps/customer/list",
        },
        {
          text: "customerDetails",
          to: {
            name: "apps-customer-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "customerEdit",
          active: true,
        },
      ],
    },
  },

  // Expense

  {
    path: "/apps/expense/list",
    name: "apps-expense-list",
    component: () =>
      import("@/views/apps/expense/expense-list/ExpensesList.vue"),
    meta: {
      pageTitle: "expense",
      breadcrumb: [
        {
          text: "expenseList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/expense/view/:id",
    name: "apps-expense-view",
    component: () =>
      import("@/views/apps/expense/expense-view/ExpensesView.vue"),
    meta: {
      pageTitle: "expense",
      breadcrumb: [
        {
          text: "expenseList",
          to: "/apps/expense/list",
        },
        {
          text: "expenseDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/expense/edit/:id",
    name: "apps-expenses-edit",
    component: () =>
      import("@/views/apps/expense/expense-edit/ExpensesEdit.vue"),
    meta: {
      pageTitle: "expense",
      breadcrumb: [
        {
          text: "expenseList",
          to: "/apps/expense/list",
        },
        {
          text: "expenseDetails",
          to: {
            name: "apps-expense-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "expenseEdit",
          active: true,
        },
      ],
    },
  },

  // Invoice

  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoicesList.vue"),
    meta: {
      pageTitle: "invoice",
      breadcrumb: [
        {
          text: "invoiceList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/invoice/view/:id",
    name: "apps-invoice-view",
    component: () =>
      import("@/views/apps/invoice/invoice-view/InvoicesView.vue"),
    meta: {
      pageTitle: "invoice",
      breadcrumb: [
        {
          text: "invoiceList",
          to: "/apps/invoice/list",
        },
        {
          text: "invoiceDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoices-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoicesEdit.vue"),
    meta: {
      pageTitle: "invoice",
      breadcrumb: [
        {
          text: "invoiceList",
          to: "/apps/invoice/list",
        },
        {
          text: "invoiceDetails",
          to: {
            name: "apps-invoice-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "invoiceEdit",
          active: true,
        },
      ],
    },
  },

  // Notice

  {
    path: "/apps/notice/list",
    name: "apps-notice-list",
    component: () => import("@/views/apps/notice/notice-list/NoticesList.vue"),
    meta: {
      pageTitle: "notice",
      breadcrumb: [
        {
          text: "noticeList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/notice/view/:id",
    name: "apps-notice-view",
    component: () => import("@/views/apps/notice/notice-view/NoticesView.vue"),
    meta: {
      pageTitle: "notice",
      breadcrumb: [
        {
          text: "noticeList",
          to: "/apps/notice/list",
        },
        {
          text: "noticeDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/notice/edit/:id",
    name: "apps-notice-edit",
    component: () => import("@/views/apps/notice/notice-edit/NoticesEdit.vue"),
    meta: {
      pageTitle: "notice",
      breadcrumb: [
        {
          text: "noticeList",
          to: "/apps/notice/list",
        },
        {
          text: "noticeDetails",
          to: {
            name: "apps-notice-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "noticeEdit",
          active: true,
        },
      ],
    },
  },

  // Diary

  {
    path: "/apps/diary/list",
    name: "apps-diary-list",
    component: () => import("@/views/apps/diary/diary-list/DiaryList.vue"),
    meta: {
      pageTitle: "diary",
      breadcrumb: [
        {
          text: "diaryList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/diary/view/:id",
    name: "apps-diary-view",
    component: () => import("@/views/apps/diary/diary-view/DiaryView.vue"),
    meta: {
      pageTitle: "diary",
      breadcrumb: [
        {
          text: "diaryList",
          to: "/apps/diary/list",
        },
        {
          text: "diaryDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/diary/edit/:id",
    name: "apps-diary-edit",
    component: () => import("@/views/apps/diary/diary-edit/DiaryEdit.vue"),
    meta: {
      pageTitle: "diary",
      breadcrumb: [
        {
          text: "diaryList",
          to: "/apps/diary/list",
        },
        {
          text: "diaryDetails",
          to: {
            name: "apps-diary-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "diaryEdit",
          active: true,
        },
      ],
    },
  },

  // Audit

  {
    path: "/apps/audit/list",
    name: "apps-audit-list",
    component: () => import("@/views/apps/audit/audit-list/AuditsList.vue"),
    meta: {
      pageTitle: "audit",
      breadcrumb: [
        {
          text: "auditList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/audit/view/:id",
    name: "apps-audit-view",
    component: () => import("@/views/apps/audit/audit-view/AuditsView.vue"),
    meta: {
      pageTitle: "audit",
      breadcrumb: [
        {
          text: "auditList",
          to: "/apps/audit/list",
        },
        {
          text: "auditDetails",
          active: true,
        },
      ],
    },
  },

  // Tasks

  {
    path: "/apps/task/list",
    name: "apps-task-list",
    component: () => import("@/views/apps/task/task-list/TasksList.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "task",
      breadcrumb: [
        {
          text: "taskList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/task/view/:id",
    name: "apps-task-view",
    component: () => import("@/views/apps/task/task-view/TasksView.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "task",
      breadcrumb: [
        {
          text: "taskList",
          to: "/apps/task/list",
        },
        {
          text: "taskDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/task/edit/:id",
    name: "apps-task-edit",
    component: () => import("@/views/apps/task/task-edit/TasksEdit.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "task",
      breadcrumb: [
        {
          text: "taskList",
          to: "/apps/task/list",
        },
        {
          text: "taskDetails",
          to: {
            name: "apps-task-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "taskEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/tasks/cards",
    name: "apps-task-cards",
    component: () => import("@/views/apps/task/tasks-cards/TaskCards.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "task",
      breadcrumb: [
        {
          text: "taskCards",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- KANBAN ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/apps/tasks/kanban/:projectId/:sprintId",
    name: "apps-kanban-filter",
    component: () => import("@/views/apps/task/tasks-kanban/TaskKanban.vue"),
    meta: {
      pageTitle: "task",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "taskKanban",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/tasks/kanban",
    name: "apps-kanban",
    component: () => import("@/views/apps/task/tasks-kanban/TaskKanban.vue"),
    meta: {
      pageTitle: "task",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "taskKanban",
          active: true,
        },
      ],
    },
  },  

  // Feature

  {
    path: "/apps/feature/list",
    name: "apps-feature-list",
    component: () =>
      import("@/views/apps/feature/feature-list/FeatureList.vue"),
    meta: {
      pageTitle: "feature",
      breadcrumb: [
        {
          text: "featureList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/feature/view/:id",
    name: "apps-feature-view",
    component: () =>
      import("@/views/apps/feature/feature-view/FeatureView.vue"),
    meta: {
      pageTitle: "feature",
      breadcrumb: [
        {
          text: "featureList",
          to: "/apps/feature/list",
        },
        {
          text: "featureDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/feature/edit/:id",
    name: "apps-feature-edit",
    component: () =>
      import("@/views/apps/feature/feature-edit/FeatureEdit.vue"),
    meta: {
      pageTitle: "feature",
      breadcrumb: [
        {
          text: "featureList",
          to: "/apps/feature/list",
        },
        {
          text: "featureDetails",
          to: {
            name: "apps-feature-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "featureEdit",
          active: true,
        },
      ],
    },
  },

  // Jobs

  {
    path: "/apps/jobs/list",
    name: "apps-jobs-list",
    component: () => import("@/views/apps/jobs/jobs-list/JobsList.vue"),
    meta: {
      pageTitle: "jobs",
      breadcrumb: [
        {
          text: "jobsList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/jobs/view/:id",
    name: "apps-jobs-view",
    component: () => import("@/views/apps/jobs/jobs-view/JobsView.vue"),
    meta: {
      pageTitle: "jobs",
      breadcrumb: [
        {
          text: "jobsList",
          to: "/apps/jobs/list",
        },
        {
          text: "jobsDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/jobs/edit/:id",
    name: "apps-jobs-edit",
    component: () => import("@/views/apps/jobs/jobs-edit/JobsEdit.vue"),
    meta: {
      pageTitle: "jobs",
      breadcrumb: [
        {
          text: "jobsList",
          to: "/apps/jobs/list",
        },
        {
          text: "jobsDetails",
          to: {
            name: "apps-jobs-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "jobsEdit",
          active: true,
        },
      ],
    },
  },

  //  ORGANIZATION

  {
    path: "/apps/organization/list",

    name: "apps-organization-list",
    component: () =>
      import(
        "@/views/apps/organization/organization-list/OrganizationList.vue"
      ),
    meta: {
      pageTitle: "organization",
      breadcrumb: [
        {
          text: "organization",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/organization/edit/:id",
    name: "apps-organization-edit",
    component: () =>
      import(
        "@/views/apps/organization/organization-edit/OrganizationEdit.vue"
      ),
    meta: {
      pageTitle: "organizationList",
      breadcrumb: [
        {
          text: "organizationList",
          to: "/apps/organization/list",
        },
        {
          text: "organizationDetails",
          to: {
            name: "apps-organization-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "Organization edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/organization/view/:id",
    name: "apps-organization-view",
    component: () =>
      import(
        "@/views/apps/organization/organization-view/OrganizationView.vue"
      ),
    meta: {
      pageTitle: "organizationList",
      breadcrumb: [
        {
          text: "organizationList",
          to: "/apps/organization/list",
        },
        {
          text: "organizationDetails",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/organization/details",
    name: "apps-organization-details",
    component: () =>
      import(
        "@/views/apps/organization/organization-details/OrganizationDetails.vue"
      ),
    meta: {
      pageTitle: "organizationDetails",
    },
  },  

  {
    path: "/apps/organization/details/edit",
    name: "apps-organization-details-edit",
    component: () =>
      import(
        "@/views/apps/organization/organization-details/OrganizationDetailsEdit.vue"
      ),
      meta: {
        pageTitle: "organizationDetails",
        breadcrumb: [
          {
            text: "organizationDetails",
            to: "/apps/organization/details",
          },
          {
            text: "organizationEdit",
            active: true,
          },
        ],
      },
  },    

  // PROFILE EDIT

  {
    path: "/apps/profile/edit",
    name: "apps-profile-edit",
    component: () =>
      import(
        "@/views/pages/profile/profile-pages/profile-edit/ProfileEdit.vue"
      ),
    meta: {
      pageTitle: "profileList",
      breadcrumb: [
        {
          text: "profileList",
          to: "/apps/profile/list",
        },
        // {
        //   text: "profileDetails",
        //   to: {
        //     name: "apps-profile-view",
        //     params: { token: (vue) => vue.$route.params.token },
        //   },
        // },
        {
          text: "Profile edit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/profile/list",

    name: "apps-profile-list",
    component: () =>
      import(
        "@/views/pages/profile/profile-pages/profile-list/ProfileList.vue"
      ),
    meta: {
      pageTitle: "profile",
      breadcrumb: [
        {
          text: "profile",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/apps/profile/view/:id",
  //   name: "apps-profile-view",
  //   component: () =>
  //     import("@/views/pages/profile/profile-pages/profile-view/ProfileView.vue"),
  //   meta: {
  //     pageTitle: "profileList",
  //     breadcrumb: [
  //       {
  //         text: "profileList",
  //         to: "/apps/profile/list",
  //       },
  //       {
  //         text: "profileDetails",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // Calendar

  {
    path: "/apps/calendar",
    name: "apps-calendar",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
  },

  // Charts

  {
    path: "/apps/chart/project/evolution",
    name: "apps-chart-project-evolution",
    component: () => import("@/views/apps/charts/Project/ProjectEvolution.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "projects_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/timeSheet/calendar",
    name: "apps-chart-timesheet-calendar",
    component: () =>
      import("@/views/apps/charts/TimeSheet/TimeSheetCalendar.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "hours_late",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/invoices",
    name: "apps-chart-invoices",
    component: () => import("@/views/apps/charts/Invoices/Invoices.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "billToReceive_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/expenses",
    name: "apps-chart-expenses",
    component: () => import("@/views/apps/charts/Expense/Expenses.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "expenses_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/customer",
    name: "apps-chart-customer-turnover",
    component: () => import("@/views/apps/charts/Customer/Turnover.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "turnover_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/contract/receive",
    name: "apps-chart-contracts-to-receive",
    component: () =>
      import("@/views/apps/charts/Contract/ContractsToReceive.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "contractsToReceive_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/cash/flow",
    name: "apps-chart-cash-flow",
    component: () => import("@/views/apps/charts/Cash/CashFlow.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "cashFlow_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/contract/result",
    name: "apps-chart-contract-result",
    component: () => import("@/views/apps/charts/Contract/ContractResult.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "contractResult_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/opportunity/status",
    name: "apps-chart-opportunity-status",
    component: () =>
      import("@/views/apps/charts/Opportunity/Opportunities.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "opportunity_chart",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/data/status",
    name: "apps-chart-data-status",
    component: () =>
      import("@/views/apps/charts/Data/Data.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "data_chart",
          active: true,
        },
      ],
    },
  },  

  // point

  {
    path: "/apps/point/list",
    name: "apps-point-list",
    component: () => import("@/views/apps/point/point-list/PointList.vue"),
    meta: {
      pageTitle: "point",
      breadcrumb: [
        {
          text: "pointList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/point/view/:id",
    name: "apps-point-view",
    component: () => import("@/views/apps/point/point-view/PointView.vue"),
    meta: {
      pageTitle: "point",
      breadcrumb: [
        {
          text: "pointList",
          to: "/apps/point/list",
        },
        {
          text: "pointDetails",
          active: true,
        },
      ],
    },
  },

  // Sprint

  {
    path: "/apps/sprint/list",
    name: "apps-sprint-list",
    component: () => import("@/views/apps/sprint/sprint-list/SprintsList.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "sprint",
      breadcrumb: [
        {
          text: "sprintList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/sprint/view/:id",
    name: "apps-sprint-view",
    component: () => import("@/views/apps/sprint/sprint-view/SprintsView.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "sprint",
      breadcrumb: [
        {
          text: "sprintCards",
          to: "/apps/sprints/cards",
        },
        {
          text: "sprintDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/sprint/edit/:id",
    name: "apps-sprint-edit",
    component: () => import("@/views/apps/sprint/sprint-edit/SprintsEdit.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "sprint",
      breadcrumb: [
        {
          text: "sprintCards",
          to: "/apps/sprints/cards",
        },
        {
          text: "sprintDetails",
          to: {
            name: "apps-sprint-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "sprintEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/sprints/cards",
    name: "apps-sprint-cards",
    component: () => import("@/views/apps/sprint/sprint-cards/SprintCards.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "sprint",
      breadcrumb: [
        {
          text: "sprintCards",
          active: true,
        },
      ],
    },
  },

  // Product

  {
    path: "/apps/product/view/:id",
    name: "apps-product-view",
    component: () =>
      import("@/views/apps/product/product-view/ProductsView.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "product",
      breadcrumb: [
        {
          text: "productCards",
          to: "/apps/products/cards",
        },
        {
          text: "productDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/product/edit/:id",
    name: "apps-product-edit",
    component: () =>
      import("@/views/apps/product/product-edit/ProductsEdit.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "product",
      breadcrumb: [
        {
          text: "productCards",
          to: "/apps/products/cards",
        },
        {
          text: "productDetails",
          to: {
            name: "apps-product-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "productEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/products/cards",
    name: "apps-product-cards",
    component: () =>
      import("@/views/apps/product/product-cards/ProductCards.vue"),
    meta: {
      contentClass: "ecommerce-application",
      pageTitle: "product",
      breadcrumb: [
        {
          text: "productCards",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo/tag/:tag",
    name: "apps-todo-tag",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["team", "low", "medium", "high", "update"].includes(to.params.tag))
        next();
      else next({ name: "error-404" });
    },
  },

  //Bugs

  {
    path: "/apps/tasks/kanban/bugs",
    name: "apps-bugs",
    component: () =>
      import("@/views/apps/task/tasks-kanban/TaskKanbanBugs.vue"),
    meta: {
      pageTitle: "Bugs",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "taskKanban",
          active: true,
        },
      ],
    },
  },

  // Asset

  {
    path: "/apps/asset/list",
    name: "apps-asset-list",
    component: () => import("@/views/apps/asset/asset-list/AssetsList.vue"),
    meta: {
      pageTitle: "asset",
      breadcrumb: [
        {
          text: "assetList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/asset/view/:id",
    name: "apps-asset-view",
    component: () => import("@/views/apps/asset/asset-view/AssetsView.vue"),
    meta: {
      pageTitle: "asset",
      breadcrumb: [
        {
          text: "assetList",
          to: "/apps/asset/list",
        },
        {
          text: "assetDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/asset/edit/:id",
    name: "apps-assets-edit",
    component: () => import("@/views/apps/asset/asset-edit/AssetsEdit.vue"),
    meta: {
      pageTitle: "asset",
      breadcrumb: [
        {
          text: "assetList",
          to: "/apps/asset/list",
        },
        {
          text: "assetDetails",
          to: {
            name: "apps-asset-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "assetEdit",
          active: true,
        },
      ],
    },
  },

  // Account

  {
    path: "/apps/account/list",
    name: "apps-account-list",
    component: () =>
      import("@/views/apps/account/account-list/AccountsList.vue"),
    meta: {
      pageTitle: "account",
      breadcrumb: [
        {
          text: "accountList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/account/view/:id",
    name: "apps-account-view",
    component: () =>
      import("@/views/apps/account/account-view/AccountsView.vue"),
    meta: {
      pageTitle: "account",
      breadcrumb: [
        {
          text: "accountList",
          to: "/apps/account/list",
        },
        {
          text: "accountDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/account/edit/:id",
    name: "apps-accounts-edit",
    component: () =>
      import("@/views/apps/account/account-edit/AccountsEdit.vue"),
    meta: {
      pageTitle: "account",
      breadcrumb: [
        {
          text: "accountList",
          to: "/apps/account/list",
        },
        {
          text: "accountDetails",
          to: {
            name: "apps-account-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "accountEdit",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/chart/accounts",
    name: "apps-chart-accounts",
    component: () => import("@/views/apps/charts/Account/Accounts.vue"),
    meta: {
      pageTitle: "chart",
      breadcrumb: [
        {
          text: "accounts_chart",
          active: true,
        },
      ],
    },
  },

  // Bank

  {
    path: "/apps/bank/list",
    name: "apps-bank-list",
    component: () => import("@/views/apps/bank/bank-list/BanksList.vue"),
    meta: {
      pageTitle: "bank",
      breadcrumb: [
        {
          text: "bankList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/bank/view/:id",
    name: "apps-bank-view",
    component: () => import("@/views/apps/bank/bank-view/BanksView.vue"),
    meta: {
      pageTitle: "bank",
      breadcrumb: [
        {
          text: "bankList",
          to: "/apps/bank/list",
        },
        {
          text: "bankDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/bank/edit/:id",
    name: "apps-banks-edit",
    component: () => import("@/views/apps/bank/bank-edit/BanksEdit.vue"),
    meta: {
      pageTitle: "bank",
      breadcrumb: [
        {
          text: "bankList",
          to: "/apps/bank/list",
        },
        {
          text: "bankDetails",
          to: {
            name: "apps-bank-view",
            params: { token: (vue) => vue.$route.params.token },
          },
        },
        {
          text: "bankEdit",
          active: true,
        },
      ],
    },
  },


  // License

  {
    path: "/apps/license/list",
    name: "apps-license-list",
    component: () => import("@/views/apps/license/license-list/LicensesList.vue"),
    meta: {
      pageTitle: "license",
      breadcrumb: [
        {
          text: "licenseList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/license/view/:id",
    name: "apps-license-view",
    component: () => import("@/views/apps/license/license-view/LicensesView.vue"),
    meta: {
      pageTitle: "license",
      breadcrumb: [
        {
          text: "licenseList",
          to: "/apps/license/list",
        },
        {
          text: "licenseDetails",
          active: true,
        },
      ],
    },
  },

];
