export default [
  {
    path: "/dashboard/administrative",
    name: "dashboard-administrative",
    component: () =>
      import("@/views/dashboard/administrative/Administrative.vue"),
  },
  {
    path: "/dashboard/overview",
    name: "dashboard-overview",
    component: () => import("@/views/dashboard/overview/Overview.vue"),
  },
  {
    path: "/dashboard/organization",
    name: "dashboard-organization",
    component: () => import("@/views/dashboard/organization/Organization.vue"),
  },  
];
