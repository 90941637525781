import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    password: "",
    userData: {},
    paramData: {},
    accessControlData: {},
    employeeData: {},
  },
  getters: {},
  mutations: {
    UPDATE_PASSWORD(state, payload) {
      state.password = payload;
    },

    UPDATE_USER_DATA(state, payload) {
      state.userData = payload;
    },

    UPDATE_PARAM_DATA(state, payload) {
      state.paramData = payload;
    },

    UPDATE_ACCESSCONTROL_DATA(state, payload) {
      state.accessControlData = payload;
    },

    UPDATE_EMPLOYEE_DATA(state, payload) {
      state.employeeData = payload;
    },
  },
  actions: {},
};
