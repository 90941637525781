<template>
  <section class="js-horizontal-collapse horizontal-collapse">
    <ul class="horizontal-collapse__inner">
      <HorizontalCollapseItem
        :className="hci + (index + 1)"
        v-for="(todo, index) in todoList"
        :key="todo.id"
      >
        <div class="horizontal-collapse__inactive-content">
          <h2 class="horizontal-collapse__heading">
            {{ todo.title.slice(0, 13) + "..." }}
          </h2>
        </div>
        <div class="horizontal-collapse__active-content">
          <!--<span style="float: right">
            <feather-icon icon="XIcon" size="20" />
          </span>-->
          <p class="horizontal-collapse__body">
            <small class="notification-text" v-html="todo.description"></small>
          </p>
        </div>
        <div>
          <b-button size="sm" @click="closeTodo(todo)" class="mr-1">Finalizar</b-button>
          <b-button size="sm" :to="{ name: 'apps-todo' }">Visualizar</b-button>
        </div>
      </HorizontalCollapseItem>
    </ul>
  </section>
</template>

<script>
import { BButton } from "bootstrap-vue";
import HorizontalCollapseItem from "./HorizontalCollapseItem";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HorizontalCollapse",
  components: {
    BButton,
    HorizontalCollapseItem,
  },

  async created() {
    if(this.paramData.reminder){
      if(this.userData.reminder){
          await this.getAllData();
        }
    }
  },

  mounted() {
    this.$root.$on("newStatusTodo", () => {
      if(this.paramData.reminder){
        if(this.userData.reminder){
          this.getAllData();
        }
      }
    });
  },  

  methods: {
    async getAllData() {
      await axios
        .get(`${URL_API}todo/remiders/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.todoList = response.data;
        });
    },

    async closeTodo(todo) {
      todo.status === true;
      await axios({
        method: "put",
        url: `${URL_API}todo/status/${todo.id}`,
        headers: getHeader(this.userData),
        data: todo,
      }).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.updated"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.todo.updated"),
          },
        });
      });

      this.todoList = [];
      await this.getAllData();
    },
  },

  data() {
    return {
      todoList: [],
      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,
      hci: "horizontal-collapse__item--",
    };
  },
};
</script>

<style lang="scss">
$transitionDurationMs: 0.5s;
.horizontal-collapse {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
  color: #fff;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  &__item {
    min-height: 1rem;
    max-height: 2rem;
    border-top-right-radius: 15px;
    list-style: none;
    padding: 0;
    overflow: hidden;
    min-width: 10rem;
    max-width: 40rem;
    position: relative;
    transition: width $transitionDurationMs;
    &--1 {
      background-color: #ce2f2f;
    }
    &--2 {
      background-color: #d33939;
    }
    &--3 {
      background-color: #c54646;
    }
    &--4 {
      background-color: #b98b8b;
    }
    &--5 {
      background-color: #ce4e4e;
    }
    &.is-active {
      min-height: 1rem;
      max-height: 80rem;
      // color: #fff;
      .horizontal-collapse__inactive-content {
        opacity: 0;
      }
      .horizontal-collapse__active-content {
        opacity: 1;
      }
    }
  }
  &__item-inner {
    padding: 1rem;
  }
  &__inactive-content {
    opacity: 1;
    transition: opacity $transitionDurationMs;
    position: absolute;
    bottom: 15px;
    left: 1rem;
    .horizontal-collapse__heading {
      cursor: default;
      font-size: 1rem;
      color: white;
      line-height: 0;
      margin-bottom: 0;
    }
  }
  &__active-content {
    opacity: 0;
    transition: opacity $transitionDurationMs;
    cursor: default;
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 2rem;
    color: white;
  }
  &__body {
    margin-bottom: 2rem;
  }
  &__link {
    display: inline-block;
    background-color: #fff;
    color: #333;
    text-decoration: none;
    line-height: 1;
    padding: 1rem 2rem;
    border-radius: 2rem;
    font-size: 1.7rem;
    font-weight: bold;
  }
}
</style>
