export default function getHeader(user) {
  if (user === undefined || user === null) {
  } else {
    var headers = {
      Authorization: "Bearer " + user.token,
      "Content-Type": "application/json;charset=UTF-8",
      "X-TenantID": user.tenant,
    };

    return headers;
  }
}
