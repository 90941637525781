export default function browserLocale(value) {
  if (value === "pt-BR") {
    return "pt";
  } else if (value === "es-ES") {
    return "es";
  } else if (value === "en-US") {
    return "en";
  } else {
    return "pt";
  }
}
