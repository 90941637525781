import store from "@/store";

export default function showMenuAccordingToRestrictions(idRestriction) {
  var accessControlList = store.state.user.accessControlData;
  var currentUserObj = store.state.user.userData;

  var currentUserAccessControl = [];

  if (accessControlList != null && idRestriction != undefined) {
    if (currentUserObj.userGroup == "accessControlAdministrative") {
      currentUserAccessControl = accessControlList.accessControlAdministrative;
    }
    if (currentUserObj.userGroup == "accessControlFinancial") {
      currentUserAccessControl = accessControlList.accessControlFinancial;
    }
    if (currentUserObj.userGroup == "accessControlManager") {
      currentUserAccessControl = accessControlList.accessControlManager;
    }
    if (currentUserObj.userGroup == "accessControlUser") {
      currentUserAccessControl = accessControlList.accessControlUser;
    }
    if (currentUserObj.userGroup == "accessControlDirector") {
      currentUserAccessControl = accessControlList.accessControlDirector;
    }
    if (currentUserObj.userGroup == "accessControlAdministrators") {
      currentUserAccessControl = accessControlList.accessControlAdministrators;
    }
    if (currentUserObj.userGroup == "accessControlApplication") {
      currentUserAccessControl = accessControlList.accessControlApplication;
    }
    if (currentUserObj.userGroup == "accessControlCustomer") {
      currentUserAccessControl = accessControlList.accessControlCustomer;
    }
    if (currentUserObj.userGroup == "accessControlCommercial") {
      currentUserAccessControl = accessControlList.accessControlCommercial;
    }

    for (var i = 0; i < currentUserAccessControl.length; i++) {
      if (currentUserObj.systemRole == "ROLE_ADMIN") {
        if (idRestriction === "apps-command") {
          return true;
        } else if (idRestriction === "apps-system-header") {
          return true;
        } else if (idRestriction === "apps-feature-list") {
          return true;
        } else if (idRestriction === "apps-config-folder") {
          return true;
        } else if (idRestriction === "apps-param") {
          return true;
        } else if (idRestriction === "apps-jobs-list") {
          return true;
        } else if (idRestriction === "apps-access-control") {
          return true;
        } else if (idRestriction === "apps-database-purge") {
          return true;
        } else if (idRestriction === "apps-database-header") {
          return true;
        } else if (idRestriction === "apps-business-header") {
          return true;
        } else if (idRestriction === "apps-organization-folder") {
          return true;
        } else if (idRestriction === "apps-organization-list") {
          return true;
        } else if (idRestriction === "button_edit_organization") {
          return true;
        } else if (idRestriction === "button_reject_organization") {
          return true;
        } else if (idRestriction === "organization_file_download") {
          return true;
        } else if (idRestriction === "organization_file_delete") {
          return true;
        } else {
          return false;
        }
      } else if (
        idRestriction == currentUserAccessControl[i].feature_id &&
        currentUserAccessControl[i].status == true
      ) {
        return true;
      }
    }
  }
}
