import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from "@/@core/utils/locale";

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  pt: {
    currency: {
      style: "currency",
      currency: "BRL",
      currencyDisplay: "symbol",
    },
  },
  es: {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
};

const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
  },
  pt: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
  es: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
};

function getlocale() {
  return locale(Intl.DateTimeFormat().resolvedOptions().locale);
}

export default new VueI18n({
  locale: getlocale(),
  fallbackLocale: getlocale(),
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  numberFormats,
  dateTimeFormats,
});
