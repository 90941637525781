import mock from "@/@fake-db/mock";

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost("/apps/calendar/events").reply((config) => {
  // Get event from post data
  const { event } = JSON.parse(config.data);

  const { length } = data.events;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.events[length - 1].id;
  }
  event.id = lastIndex + 1;

  data.events.push(event);

  return [201, { event }];
});

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply((config) => {
  const { event: eventData } = JSON.parse(config.data);

  // Convert Id to number
  eventData.id = Number(eventData.id);

  const event = data.events.find((e) => e.id === Number(eventData.id));
  Object.assign(event, eventData);

  return [200, { event }];
});

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply((config) => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  eventId = Number(eventId);

  const eventIndex = data.events.findIndex((e) => e.id === eventId);
  data.events.splice(eventIndex, 1);
  return [200];
});
