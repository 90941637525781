import URL_API from "@core/utils/env";

export default {
  // Endpoints
  loginEndpoint: `${URL_API}auth`,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${URL_API}auth/refreshtoken`,
  logoutEndpoint: `${URL_API}auth/signout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer ",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};
